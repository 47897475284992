<template>
  <tr :class="rowClass" v-if="!header" :id="rowId">
    <td :class="padding"><small><strong>{{ fills }}</strong></small></td>
    <td :class="padding"><small><strong>{{ dinar }}</strong></small></td>
    <td :class="padding" colspan="2">{{ desc }}</td>
    <td :class="padding" colspan="2">{{ note }}</td>
    <td :class="padding" colspan="2">
      <div v-if="!canNotDelete">
        <modal-btn :target="`#${deleteId}`" btn-class="btn-sm pm-0" title="إزالة بند">
          <i class="fa fa-trash-alt link danger pm-0"></i>
        </modal-btn>
        <teleport to="#app">
          <confirm-model
            confirm-btn="btn-secondary"
            :target-id="deleteId"
            confirm-text="إزالة"
            title="إزالة بند"
            @confirmed="deleteItem">
            <label>
              <span>هل أنت متأكد من إزالة البند</span>&nbsp;<span class="link">{{ item.desc }}</span>&nbsp;؟
            </label>
          </confirm-model>
        </teleport>
      </div>
    </td>
  </tr>
  <tr :class="rowClass" v-else :id="rowId">
    <th><small><strong>{{ fills }}</strong></small></th>
    <th><small><strong>{{ dinar }}</strong></small></th>
    <th colspan="2">{{ desc }}</th>
    <th colspan="2">{{ note }}</th>
    <th colspan="2"></th>
  </tr>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";
import ModalBtn from "@/components/UI/ModalBtn";

export default {
  components: {ModalBtn, ConfirmModel},
  emits: ['deleted'],
  props: {
    item: {required: true},
    header: {},
    rowClass: {},
    edit: {default: false},
  },
  computed: {
    hasData() {
      return !!this.fills || !!this.dinar || !!this.desc || !!this.note;
    },
    padding() {
      return this.hasData ? 'py-0' : 'py-2';
    },
    rowId() {
      return !this.item || !this.item.id ? '' : `invoice-row${this.item.id}`;
    },
    deleteId() {
      return !this.item ? '' : `invoice-row-delete${this.item.id}`;
    },
    canNotDelete() {
      return !this.item || !this.item.id || !this.edit;
    },
    fills() {
      return !!this.item ? this.item.fills : '';
    },
    dinar() {
      return !!this.item ? this.item.dinar : '';
    },
    desc() {
      return !!this.item ? this.item.desc : '';
    },
    note() {
      return !!this.item ? this.item.note : '';
    },
  },
  methods: {
    deleteItem() {
      if (!this.item)
        return;
      this.hideModal(`#${this.deleteId}`);
      return this.$emit('deleted', this.item.id);
    },
  }
}
</script>

<style scoped>
/*td, th {*/
/*  border-color: #343a40 !important;*/
/*}*/

th {
  padding: 0.5rem;
}
</style>